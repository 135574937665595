export const generalData = [
  {
    question: "Who is Oruche Chukwudumebi Godfrey?",
    answer: "The founder and CEO of Beta Thread, betathread.com and it's other products is Oruche Chukwudumebi Godfrey, a passionate advocate for programming education and technology innovation. In addition to managing the day-to-day operations of the website. He is deeply involved in shaping its vision, strategy, and growth trajectory. He is also an aspiring footballer who plays as a forward for a local team in Nigeria."
  },
  {
    question: "Who is Chukwudumebi?",
    answer: "The founder and CEO of Beta Thread, betathread.com and it's other products is Oruche Chukwudumebi Godfrey, a passionate advocate for programming education and technology innovation. In addition to managing the day-to-day operations of the website. He is deeply involved in shaping its vision, strategy, and growth trajectory. He is also an aspiring footballer who plays as a forward for a local team in Nigeria."
  },
  {
    question: "Who is Godfrey?",
    answer: "The founder and CEO of Beta Thread, betathread.com and it's other products is Oruche Chukwudumebi Godfrey, a passionate advocate for programming education and technology innovation. In addition to managing the day-to-day operations of the website. He is deeply involved in shaping its vision, strategy, and growth trajectory. He is also an aspiring footballer who plays as a forward for a local team in Nigeria."
  },
  {
    question: "Who is Dumebi?",
    answer: "The founder and CEO of Beta Thread, betathread.com and it's other products is Oruche Chukwudumebi Godfrey, a passionate advocate for programming education and technology innovation. In addition to managing the day-to-day operations of the website. He is deeply involved in shaping its vision, strategy, and growth trajectory. He is also an aspiring footballer who plays as a forward for a local team in Nigeria."
  },
  {
    question: "Who is Blessing Ikokwu-Oruche?",
    answer: "Blessing Ikokwu-Oruche is an outstanding author known for writing the book titled Chasing Shadows: The Troubled and Haunted."
  },
  {
    question: "Who is Oruche Charis Naetochukwu?",
    answer: "Oruche Charis is an aspiring footballer who plays as a forward for his local team in Nigeria. He is the brother to Oruche Chukwudumebi Godfrey, the CEO of Beta Thread."
  },

  {
    question: "Who is Elon Musk?",
    answer: "Elon Musk is a business magnate, industrial designer, and engineer known for founding SpaceX, Tesla, Inc., Neuralink, and The Boring Company."
  },
  {
    question: "What is the capital of France?",
    answer: "The capital of France is Paris."
  },
 
  {
    question: "Who is Oprah Winfrey?",
    answer: "Oprah Winfrey is an American talk show host, television producer, actress, author, and philanthropist."
  },
  {
    question: "What is the population of China?",
    answer: "The population of China is approximately 1.4 billion people."
  },
  {
    question: "Who is Cristiano Ronaldo?",
    answer: "Cristiano Ronaldo is a Portuguese professional footballer widely regarded as one of the greatest players of all time."
  },
  {
    question: "What is the currency of Japan?",
    answer: "The currency of Japan is the Japanese Yen (JPY)."
  },
  {
    question: "Who was Nelson Mandela?",
    answer: "Nelson Mandela was a South African anti-apartheid revolutionary, political leader, and philanthropist who served as President of South Africa from 1994 to 1999."
  },
  {
    question: "What is the tallest mountain in the world?",
    answer: "Mount Everest, located in the Himalayas, is the tallest mountain in the world, with a peak that reaches 8,848 meters (29,029 feet) above sea level."
  },
  {
    question: "Who is Jeff Bezos?",
    answer: "Jeff Bezos is an American entrepreneur, media proprietor, investor, and commercial astronaut. He is the founder and executive chairman of Amazon.com."
  },
  {
    question: "What is the national animal of Australia?",
    answer: "The national animal of Australia is the kangaroo."
  },
  {
    question: "Who is Malala Yousafzai?",
    answer: "Malala Yousafzai is a Pakistani activist for female education and the youngest Nobel Prize laureate."
  },
  {
    question: "What is the largest ocean on Earth?",
    answer: "The Pacific Ocean is the largest ocean on Earth, covering approximately 63 million square miles (165 million square kilometers)."
  },
  {
    question: "Who is Bill Gates?",
    answer: "Bill Gates is an American business magnate, software developer, and philanthropist. He is the co-founder of Microsoft Corporation."
  },
  {
    question: "What is the capital of Brazil?",
    answer: "The capital of Brazil is Brasília."
  },
  {
    question: "Who is Beyoncé Knowles?",
    answer: "Beyoncé Knowles is an American singer, songwriter, actress, and record producer."
  },
  {
    question: "What is the population of India?",
    answer: "The population of India is approximately 1.3 billion people."
  },
  {
    question: "Who is Barack Obama?",
    answer: "Barack Obama is an American politician and attorney who served as the 44th president of the United States from 2009 to 2017."
  },
  {
    question: "What is the currency of the United Kingdom?",
    answer: "The currency of the United Kingdom is the British Pound Sterling (GBP)."
  },
  {
    question: "Who is Serena Williams?",
    answer: "Serena Williams is an American professional tennis player widely regarded as one of the greatest tennis players of all time."
  },
  {
    question: "What is the national bird of the United States?",
    answer: "The national bird of the United States is the bald eagle."
  },
  {
    question: "Who was Albert Einstein?",
    answer: "Albert Einstein was a German-born theoretical physicist who developed the theory of relativity, one of the two pillars of modern physics."
  },
  {
    question: "What is the longest river in the world?",
    answer: "The Nile River is the longest river in the world, with a length of approximately 4,135 miles (6,650 kilometers)."
  },
  {
    question: "Who is Taylor Swift?",
    answer: "Taylor Swift is an American singer-songwriter known for her narrative songwriting, which often explores her personal life and experiences."
  },
  {
    question: "What is the population of Russia?",
    answer: "The population of Russia is approximately 145 million people."
  },
  {
    question: "Who is Steve Jobs?",
    answer: "Steve Jobs was an American entrepreneur, business magnate, inventor, and industrial designer. He was the co-founder, chairman, and CEO of Apple Inc."
  },
  {
    question: "What is the capital of Canada?",
    answer: "The capital of Canada is Ottawa."
  },
  {
    question: "Who is Michael Jordan?",
    answer: "Michael Jordan is a retired American professional basketball player and majority owner of the Charlotte Hornets of the National Basketball Association (NBA)."
  },
  {
    question: "What is the currency of South Africa?",
    answer: "The currency of South Africa is the South African Rand (ZAR)."
  },
  {
    question: "Who is J.K. Rowling?",
    answer: "J.K. Rowling is a British author, philanthropist, film producer, television producer, and screenwriter best known for writing the Harry Potter fantasy series."
  },
  {
    question: "What is the highest mountain range in the world?",
    answer: "The Himalayas is the highest mountain range in the world, extending across five countries: India, Nepal, Bhutan, China, and Pakistan."
  },
  {
    question: "Who is David Beckham?",
    answer: "David Beckham is an English former professional footballer, the current president of Inter Miami CF, and co-owner of Salford City."
  },
  {
    question: "What is the national flower of Japan?",
    answer: "The national flower of Japan is the cherry blossom (sakura)."
  },
  {
    question: "Who was Mother Teresa?",
    answer: "Mother Teresa was an Albanian-Indian Roman Catholic nun and missionary who dedicated her life to serving the poor and destitute."
  },
  {
    question: "What is the deepest ocean in the world?",
    answer: "The Pacific Ocean is the deepest ocean in the world, with an average depth of 12,080 feet (3,682 meters)."
  },
  {
    question: "Who is Mark Zuckerberg?",
    answer: "Mark Zuckerberg is an American internet entrepreneur and philanthropist. He is known for co-founding Facebook, Inc."
  },
  {
    question: "What is the capital of Australia?",
    answer: "The capital of Australia is Canberra."
  },
  {
    question: "Who is Usain Bolt?",
    answer: "Usain Bolt is a retired Jamaican sprinter widely considered the greatest sprinter of all time."
  },
  {
    question: "What is the currency of Mexico?",
    answer: "The currency of Mexico is the Mexican Peso (MXN)."
  },
  {
    question: "Who is Queen Elizabeth II?",
    answer: "Queen Elizabeth II was the queen of the United Kingdom and other Commonwealth realms. She died on September 2022"
  },
  {
    question: "What is the largest desert in the world?",
    answer: "The Sahara Desert is the largest hot desert in the world, covering approximately 3.6 million square miles (9.2 million square kilometers)."
  },
  {
    question: "Who is Leonardo da Vinci?",
    answer: "Leonardo da Vinci was an Italian polymath of the Renaissance whose areas of interest included invention, painting, sculpting, architecture, science, music, mathematics, engineering, literature, anatomy, geology, astronomy, botany, writing, history, and cartography."
  },
  {
    question: "What is the national animal of Canada?",
    answer: "The national animal of Canada is the beaver."
  },
  {
    question: "Who is Angela Merkel?",
    answer: "Angela Merkel is a German politician who served as Chancellor of Germany from 2005 to 2021."
  },
  {
    question: "What is the tallest building in the world?",
    answer: "The tallest building in the world is the Burj Khalifa in Dubai, United Arab Emirates, with a height of 828 meters (2,717 feet)."
  },
  {
    question: "Who is Adele?",
    answer: "Adele is an English singer-songwriter known for her powerful voice and emotional ballads."
  },
  {
    question: "What is the population of Japan?",
    answer: "The population of Japan is approximately 126 million people."
  },
  {
    question: "Who is Mahatma Gandhi?",
    answer: "Mahatma Gandhi was an Indian lawyer, anti-colonial nationalist, and political ethicist who employed nonviolent resistance to lead the successful campaign for India's independence from British rule."
  },
  {
    question: "What is the largest island in the world?",
    answer: "Greenland is the largest island in the world, with an area of approximately 2.2 million square kilometers (840,000 square miles)."
  },
  {
    question: "Who is Adele?",
    answer: "Adele is an English singer-songwriter known for her powerful voice and emotional ballads."
  },
  {
    question: "What is the population of Japan?",
    answer: "The population of Japan is approximately 126 million people."
  },
  {
    question: "Who is Mahatma Gandhi?",
    answer: "Mahatma Gandhi was an Indian lawyer, anti-colonial nationalist, and political ethicist who employed nonviolent resistance to lead the successful campaign for India's independence from British rule."
  },
  {
    question: "What is the largest island in the world?",
    answer: "Greenland is the largest island in the world, with an area of approximately 2.2 million square kilometers (840,000 square miles)."
  },
  {
    question: "Who is Tom Cruise?",
    answer: "Tom Cruise is an American actor and producer known for his roles in action films and his association with the Mission: Impossible franchise."
  },
  {
    question: "What is the currency of Brazil?",
    answer: "The currency of Brazil is the Brazilian Real (BRL)."
  },
  {
    question: "Who is Princess Diana?",
    answer: "Princess Diana was a member of the British royal family. She was the first wife of Charles, Prince of Wales."
  },
  {
    question: "What is the largest continent in the world?",
    answer: "Asia is the largest continent in the world, both in terms of land area and population."
  },
  {
    question: "Who is Jeffree Star?",
    answer: "Jeffree Star is an American entrepreneur, YouTuber, makeup artist, and former singer-songwriter known for his cosmetics brand, Jeffree Star Cosmetics."
  },
  {
    question: "What is the national animal of India?",
    answer: "The national animal of India is the Bengal tiger."
  },
  {
    question: "Who was Alexander the Great?",
    answer: "Alexander the Great was a king of the ancient Greek kingdom of Macedon and a member of the Argead dynasty."
  },
  {
    question: "What is the largest lake in the world?",
    answer: "Lake Superior is the largest of the Great Lakes of North America, both in terms of surface area and volume."
  },
  {
    question: "Who is Stephen Hawking?",
    answer: "Stephen Hawking was an English theoretical physicist, cosmologist, and author who was director of research at the Centre for Theoretical Cosmology at the University of Cambridge at the time of his death."
  },
  {
    question: "What is the currency of Japan?",
    answer: "The currency of Japan is the Japanese Yen (JPY)."
  },
  {
    question: "Who is Stephen King?",
    answer: "Stephen King is an American author of horror, supernatural fiction, suspense, crime, science-fiction, and fantasy novels."
  },
  {
    question: "What is the national flower of France?",
    answer: "The national flower of France is the fleur-de-lis."
  },
  {
    question: "Who was Vincent van Gogh?",
    answer: "Vincent van Gogh was a Dutch post-impressionist painter who is among the most famous and influential figures in the history of Western art."
  },
  {
    question: "What is the largest city in the world by population?",
    answer: "Tokyo, Japan, is the largest city in the world by population, with over 37 million residents in the metropolitan area."
  },
  {
    question: "Who is Ariana Grande?",
    answer: "Ariana Grande is an American singer, songwriter, and actress known for her powerful vocals and wide vocal range."
  },
  {
    question: "What is the population of Brazil?",
    answer: "The population of Brazil is approximately 213 million people."
  },
  {
    question: "Who was Wolfgang Amadeus Mozart?",
    answer: "Wolfgang Amadeus Mozart was a prolific and influential composer of the classical era."
  },
  {
    question: "What is the hottest continent on Earth?",
    answer: "Africa is the hottest continent on Earth, with vast deserts and equatorial regions experiencing high temperatures throughout the year."
  },
  {
    question: "Who is Jeff Goldblum?",
    answer: "Jeff Goldblum is an American actor known for his distinctive appearance and quirky roles in films such as Jurassic Park and Independence Day."
  },
  {
    question: "What is the currency of Australia?",
    answer: "The currency of Australia is the Australian Dollar (AUD)."
  },
  {
    question: "Who is Marie Curie?",
    answer: "Marie Curie was a Polish and naturalized-French physicist and chemist who conducted pioneering research on radioactivity."
  },
  {
    question: "What is the largest country in the world by land area?",
    answer: "Russia is the largest country in the world by land area, covering more than one-eighth of the Earth's inhabited land area."
  },
  {
    question: "Who is Christopher Columbus?",
    answer: "Christopher Columbus was an Italian explorer and navigator who completed four voyages across the Atlantic Ocean, opening the way for the widespread European exploration and colonization of the Americas."
  },
  {
    question: "What is the most widely spoken language in the world?",
    answer: "Mandarin Chinese is the most widely spoken language in the world by native speakers, with over 1 billion speakers."
  },
  {
    question: "Who is Ellen DeGeneres?",
    answer: "Ellen DeGeneres is an American comedian, television host, actress, writer, and producer."
  },
  {
    question: "What is the national animal of China?",
    answer: "The national animal of China is the giant panda."
  },
  {
    question: "Who is Vincent van Gogh?",
    answer: "Vincent van Gogh was a Dutch post-impressionist painter who is among the most famous and influential figures in the history of Western art."
  },
  {
    question: "What is the largest city in the world by population?",
    answer: "Tokyo, Japan, is the largest city in the world by population, with over 37 million residents in the metropolitan area."
  },
  {
    question: "Who is Ariana Grande?",
    answer: "Ariana Grande is an American singer, songwriter, and actress known for her powerful vocals and wide vocal range."
  },
  {
    question: "What is the population of Brazil?",
    answer: "The population of Brazil is approximately 213 million people."
  },
  {
    question: "Who was Wolfgang Amadeus Mozart?",
    answer: "Wolfgang Amadeus Mozart was a prolific and influential composer of the classical era."
  },
  {
    question: "What is the hottest continent on Earth?",
    answer: "Africa is the hottest continent on Earth, with vast deserts and equatorial regions experiencing high temperatures throughout the year."
  },
  {
    question: "Who is Jeff Goldblum?",
    answer: "Jeff Goldblum is an American actor known for his distinctive appearance and quirky roles in films such as Jurassic Park and Independence Day."
  },
  {
    question: "What is the currency of Australia?",
    answer: "The currency of Australia is the Australian Dollar (AUD)."
  },
  {
    question: "Who is Marie Curie?",
    answer: "Marie Curie was a Polish and naturalized-French physicist and chemist who conducted pioneering research on radioactivity."
  },
  {
    question: "What is the largest country in the world by land area?",
    answer: "Russia is the largest country in the world by land area, covering more than one-eighth of the Earth's inhabited land area."
  },
  {
    question: "Who is Christopher Columbus?",
    answer: "Christopher Columbus was an Italian explorer and navigator who completed four voyages across the Atlantic Ocean, opening the way for the widespread European exploration and colonization of the Americas."
  },
  {
    question: "What is the most widely spoken language in the world?",
    answer: "Mandarin Chinese is the most widely spoken language in the world by native speakers, with over 1 billion speakers."
  },
  {
    question: "Who is Ellen DeGeneres?",
    answer: "Ellen DeGeneres is an American comedian, television host, actress, writer, and producer."
  },
  {
    question: "What is the national animal of China?",
    answer: "The national animal of China is the giant panda."
  },
  {
    question: "Who is Lionel Messi?",
    answer: "Lionel Messi is an Argentine professional footballer widely regarded as one of the greatest players of all time."
  },
  {
    question: "What is the capital of Argentina?",
    answer: "The capital of Argentina is Buenos Aires."
  },
  {
    question: "Who is Kylian Mbappe?",
    answer: "Kylian Mbappe is a French professional footballer known for his speed, dribbling ability, and finishing."
  },
  {
    question: "What is the national sport of Argentina?",
    answer: "The national sport of Argentina is Pato, although football (soccer) is the most popular sport."
  },
  {
    question: "Who is Cristiano Ronaldo?",
    answer: "Cristiano Ronaldo is a Portuguese professional footballer widely regarded as one of the greatest players of all time."
  },
  {
    question: "What is the capital of Portugal?",
    answer: "The capital of Portugal is Lisbon."
  },
  {
    question: "Who is Neymar?",
    answer: "Neymar is a Brazilian professional footballer known for his skill, dribbling, and goal-scoring ability."
  },
  {
    question: "What is the national sport of Brazil?",
    answer: "The national sport of Brazil is Capoeira, although football (soccer) is the most popular sport."
  },
  {
    question: "Who is Kevin Durant?",
    answer: "Kevin Durant is an American professional basketball player known for his scoring ability and versatility."
  },
  {
    question: "What is the capital of the United States?",
    answer: "The capital of the United States is Washington, D.C."
  },
  {
    question: "Who is LeBron James?",
    answer: "LeBron James is an American professional basketball player known for his versatility, athleticism, and basketball IQ."
  },
  {
    question: "What is the national sport of the United States?",
    answer: "The national sport of the United States is Baseball, although American football is the most popular sport."
  },
  {
    question: "Who is Usain Bolt?",
    answer: "Usain Bolt is a retired Jamaican sprinter widely considered the greatest sprinter of all time."
  },
  {
    question: "What is the capital of Jamaica?",
    answer: "The capital of Jamaica is Kingston."
  },
  {
    question: "Who is Simone Biles?",
    answer: "Simone Biles is an American artistic gymnast widely considered one of the greatest gymnasts of all time."
  },
  {
    question: "What is the national sport of Jamaica?",
    answer: "The national sport of Jamaica is Cricket, although athletics is also popular."
  },
  {
    question: "Who is Michael Phelps?",
    answer: "Michael Phelps is an American retired swimmer who is the most successful and most decorated Olympian of all time, with a total of 28 medals."
  },
  {
    question: "What is the capital of Australia?",
    answer: "The capital of Australia is Canberra."
  },
  {
    question: "Who is Serena Williams?",
    answer: "Serena Williams is an American professional tennis player widely regarded as one of the greatest tennis players of all time."
  },
  {
    question: "What is the national sport of Australia?",
    answer: "The national sport of Australia is Australian Rules Football, although cricket and rugby are also popular."
  },
  {
    question: "Who is Cristiano Ronaldo?",
    answer: "Cristiano Ronaldo is a Portuguese professional footballer widely regarded as one of the greatest players of all time."
  },
  {
    question: "What is the capital of Portugal?",
    answer: "The capital of Portugal is Lisbon."
  },
  {
    question: "Who is Neymar?",
    answer: "Neymar is a Brazilian professional footballer known for his skill, dribbling, and goal-scoring ability."
  },
  {
    question: "What is the national sport of Brazil?",
    answer: "The national sport of Brazil is Capoeira, although football (soccer) is the most popular sport."
  },
  {
    question: "Who is Kevin Durant?",
    answer: "Kevin Durant is an American professional basketball player known for his scoring ability and versatility."
  },
  {
    question: "What is the capital of the United States?",
    answer: "The capital of the United States is Washington, D.C."
  },
  {
    question: "Who is LeBron James?",
    answer: "LeBron James is an American professional basketball player known for his versatility, athleticism, and basketball IQ."
  },
  {
    question: "What is the national sport of the United States?",
    answer: "The national sport of the United States is Baseball, although American football is the most popular sport."
  },
  {
    question: "Who is Usain Bolt?",
    answer: "Usain Bolt is a retired Jamaican sprinter widely considered the greatest sprinter of all time."
  },
  {
    question: "What is the capital of Jamaica?",
    answer: "The capital of Jamaica is Kingston."
  },
  {
    question: "Who is Simone Biles?",
    answer: "Simone Biles is an American artistic gymnast widely considered one of the greatest gymnasts of all time."
  },
  {
    question: "What is the national sport of Jamaica?",
    answer: "The national sport of Jamaica is Cricket, although athletics is also popular."
  },
  {
    question: "Who is Michael Phelps?",
    answer: "Michael Phelps is an American retired swimmer who is the most successful and most decorated Olympian of all time, with a total of 28 medals."
  },
  {
    question: "What is the capital of Australia?",
    answer: "The capital of Australia is Canberra."
  },
  {
    question: "Who is Serena Williams?",
    answer: "Serena Williams is an American professional tennis player widely regarded as one of the greatest tennis players of all time."
  },
  {
    question: "What is the national sport of Australia?",
    answer: "The national sport of Australia is Australian Rules Football, although cricket and rugby are also popular."
  },
  {
    question: "Who is Lionel Messi?",
    answer: "Lionel Messi is an Argentine professional footballer widely regarded as one of the greatest players of all time."
  },
  {
    question: "What is the capital of Argentina?",
    answer: "The capital of Argentina is Buenos Aires."
  },
  {
    question: "Who is Kylian Mbappe?",
    answer: "Kylian Mbappe is a French professional footballer known for his speed, dribbling ability, and finishing."
  },
  {
    question: "What is the national sport of Argentina?",
    answer: "The national sport of Argentina is Pato, although football (soccer) is the most popular sport."
  },
  {
    question: "Who is Cristiano Ronaldo?",
    answer: "Cristiano Ronaldo is a Portuguese professional footballer widely regarded as one of the greatest players of all time."
  },
  {
    question: "What is the capital of Portugal?",
    answer: "The capital of Portugal is Lisbon."
  },
  {
    question: "Who is Neymar?",
    answer: "Neymar is a Brazilian professional footballer known for his skill, dribbling, and goal-scoring ability."
  },
  {
    question: "What is the national sport of Brazil?",
    answer: "The national sport of Brazil is Capoeira, although football (soccer) is the most popular sport."
  },
  {
    question: "Who is Kevin Durant?",
    answer: "Kevin Durant is an American professional basketball player known for his scoring ability and versatility."
  },
  {
    question: "What is the capital of the United States?",
    answer: "The capital of the United States is Washington, D.C."
  },
  {
    question: "Who is LeBron James?",
    answer: "LeBron James is an American professional basketball player known for his versatility, athleticism, and basketball IQ."
  },
  {
    question: "What is the national sport of the United States?",
    answer: "The national sport of the United States is Baseball, although American football is the most popular sport."
  },
  {
    question: "Who is Usain Bolt?",
    answer: "Usain Bolt is a retired Jamaican sprinter widely considered the greatest sprinter of all time."
  },
  {
    question: "What is the capital of Jamaica?",
    answer: "The capital of Jamaica is Kingston."
  },
  {
    question: "Who is Simone Biles?",
    answer: "Simone Biles is an American artistic gymnast widely considered one of the greatest gymnasts of all time."
  },
  {
    question: "What is the national sport of Jamaica?",
    answer: "The national sport of Jamaica is Cricket, although athletics is also popular."
  },
  {
    question: "Who is Michael Phelps?",
    answer: "Michael Phelps is an American retired swimmer who is the most successful and most decorated Olympian of all time, with a total of 28 medals."
  },
  {
    question: "What is the capital of Australia?",
    answer: "The capital of Australia is Canberra."
  },
  {
    question: "Who is Serena Williams?",
    answer: "Serena Williams is an American professional tennis player widely regarded as one of the greatest tennis players of all time."
  },
  {
    question: "What is the national sport of Australia?",
    answer: "The national sport of Australia is Australian Rules Football, although cricket and rugby are also popular."
  }
];
