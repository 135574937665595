import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthStatus } from '../../localstorage';

export default function TodoList() {
    const [tasks, setTasks] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate(); // Move useNavigate here

  useEffect(() => {
    const checkAuthentication = () => {
      const isAuthenticated = getAuthStatus('_id');
      if (!isAuthenticated) {
        navigate("/login");
      } else {
        setAuthenticated(true);
      }
    };

    checkAuthentication();
  }, [navigate]);


    const handleAddTask = () => {
        if (inputValue.trim() !== '') {
            setTasks([...tasks, inputValue]);
            setInputValue('');
        }
    };

    const handleDeleteTask = index => {
        const newTasks = [...tasks];
        newTasks.splice(index, 1);
        setTasks(newTasks);
    };

    return (
        <div>
            <h1>Todo List Application</h1>
            <input
                type="text"
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
            />
            <button onClick={handleAddTask}>Add Task</button>
            <ul>
                {tasks.map((task, index) => (
                    <li key={index}>
                        {task}
                        <button onClick={() => handleDeleteTask(index)}>Delete</button>
                    </li>
                ))}
            </ul>
            <div>
                <h2>Code Snippet (HTML):</h2>
                <pre>{`
                <input
                    type="text"
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                />
                <button onClick={handleAddTask}>Add Task</button>
                <ul>
                    {tasks.map((task, index) => (
                        <li key={index}>
                            {task}
                            <button onClick={() => handleDeleteTask(index)}>Delete</button>
                        </li>
                    ))}
                </ul>
                `}</pre>
            </div>
            <div>
                <h2>Code Snippet (CSS):</h2>
                <pre>{`
                /* CSS for Todo List Component */
                ul {
                    list-style-type: none;
                    padding: 0;
                }

                li {
                    margin-bottom: 5px;
                }

                li button {
                    margin-left: 10px;
                    cursor: pointer;
                }
                `}</pre>
            </div>

            <div>
                <h2>Code Snippet (JSX):</h2>
                <pre>{`
                import React, { useState } from 'react';

                export default function TodoList() {
                    const [tasks, setTasks] = useState([]);
                    const [inputValue, setInputValue] = useState('');
                    const [tasks, setTasks] = useState([]);
                    const [inputValue, setInputValue] = useState('');
                
                    const handleAddTask = () => {
                        if (inputValue.trim() !== '') {
                            setTasks([...tasks, inputValue]);
                            setInputValue('');
                        }
                    };
                
                    const handleDeleteTask = index => {
                        const newTasks = [...tasks];
                        newTasks.splice(index, 1);
                        setTasks(newTasks);
                    };
                    
                `}

                </pre>
            </div>
        </div>
    );
}
