import React from "react";
import '../css/my style.css';
import "../css/my style2.css";
import "../css/special styles.css";
import Signupbuttonlight from "./Signupbuttonlight";
import ScrollToTopLink from "./ScrollToTop"; // Import the ScrollToTopLink component
import CertificatePage from "../Payment/CertificatePage";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import Analytics from "./Analytics";
import ai from "../image/beta_ai.png";
import html from "../image/pic2.jpg"
import { Helmet } from "react-helmet";
import SyntaxHighlighterComponent from "./SyntaxHighlighterComponent-special";
import Tryitout from "./Tryitoutbutton";
import ProgressTracker from "./Tutorial Progress/ProgressTracker";
import DemoProgress from "./Tutorial Progress/DemoProgress";


function Body() {
  var navButton = {
    width: 'auto',
    padding: '20px',
    background: 'blue',
    color: 'white',
    margin: '1px',
    borderRadius: '20px',
    height: '45px',
    textAlign: 'center',
    cursor: 'pointer'
  };

  var firstDiv = {
    margin: '0',
    paddingTop: '10px',
    background: 'darkblue',
    width: '100%',
    color: 'white',
   
   
  };

  var secondDiv = {
    paddingTop: '10px',
    paddingBottom: '10px',
    width: '100%',
    color: 'white',
    background: 'darkblue',
    marginBottom: '0px',
  };

  var divCode = {
    width: '100%',
    float: 'right'
  };

  const myTutorials = {
    textAlign: 'center',
    color: 'white',
    fontSize: "40px"
  };

  var newCode = {
    color: 'white',
    
  };

  var newCoding = {
    color: 'white',
    textAlign: 'left'
  };

  var tutorialsCode = {
    borderRadius: '5px'
  };

  var tutorialsCodehtml = {
    backgroundColor: 'darkblue'
  };

  var tutorialsCodecss = {
    backgroundColor: '#00ccff'
  };

  var tutorialsCodejs = {
    backgroundColor: 'teal'
  };

  var tutorialsCodephp = {
    backgroundColor: 'darkblue'
  };

  var tutorialsCodepython = {
    borderRadius: '5px',
    backgroundColor: '#00ccff'
  };

  var tutorialsCodesql = {
    borderRadius: '5px',
    backgroundColor: 'teal'
  };

  var tutorialsCoderuby = {
    borderRadius: '5px',
    backgroundColor: '#00ccff'
  };

  var tutorialsCodexml = {
    borderRadius: '5px',
    backgroundColor: 'teal'
  };

  var tutorialsCodereact = {
    borderRadius: '5px',
    backgroundColor: '#00ccff'
  };

  var tutorialsCodeai = {
    borderRadius: '5px',
    backgroundColor: 'teal'
  };

  var hiddenCode = {
    display: 'none'
  };

  var lastContent = {
    color: 'white',
    height: '250px'
  };

  var sampleCode = {
    background: 'darkblue',
    margin: '15px'
  };

  const quizCode = {
    borderRadius: '10px',
    float: 'left'
  };

  const quizCodes = {
    float: 'left',
    color: 'white'
  };

  var aCode = {
    textAlign: 'left'
  };


  const code = `
  <!DOCTYPE html>
  <html>
  <head>
  <title>My First Web Page</title>
  </head>
  <body>
  <h1>Hello World!</h1>
  </body>
  </html>
`;
  
const code2 = `
h1 {
  color: green;
}
p {
  text-align: center;
}
`;

const code3 = `
<button onclick="minusNumbers()">
Click me!
</button>
<script>
function minusNumbers() {
var x = 100;
var y = 30;
var minus = x - y;
console.log(minus);
}
`;

const code4 = `
<?php
function addNumbers($num1, $num2) {
    $sum = $num1 + $num2;
    return $sum;
}

// Function call
$result = addNumbers(5, 3);
echo "Result: " . $result;
?>
`;

  return (
    <body>
    <Helmet>
    <link rel="preload" href="../images/header.png" as="header" />

    </Helmet>
      
      <div style={firstDiv} className="divsample">
   
        <h1><b>NOT SURE WHERE TO BEGIN?</b></h1>
        <h4 style={{ textAlign: "center" }}>Create your account for the best learning experience</h4>
          <ScrollToTopLink to="/signup"><button style={quizCode} class="button" type="submit">Sign Up</button></ScrollToTopLink>
        <br /><br />

          <h4 style={{ textAlign: "center" }}>This short quiz helps us process your assimilation in order to assist you on the tutorials</h4>
          <ScrollToTopLink to="/take_quiz"><button style={quizCode} class="button" type="submit">Take quiz</button></ScrollToTopLink>
        <br /><br />
          <h1 style={myTutorials}>Browse Our Tutorials</h1>

      
          <section className="features">
          <div className="beta-sample" style={tutorialsCodehtml}>
         
            <h1 style={newCode}><b>HTML Tutorial</b></h1>
            <p>Craft Seamless Web Experiences</p>
            <ScrollToTopLink to="/HTML_Tutorial_intro"><button type="submit" className="buttonb" id="tutorial-button1">Begin Tutorial</button></ScrollToTopLink>
       
          </div>

          <div className="beta-sampling" style={tutorialsCodehtml}>
            <h1>HTML Example</h1>
          <SyntaxHighlighterComponent code={code} language="html" />
          <ScrollToTopLink to="/try_html1"><Tryitout /></ScrollToTopLink>
       </div>

          
       <div className="beta-sample" style={tutorialsCodecss}>
            <h1 style={newCode}><b>CSS Tutorial</b></h1>
            <p>Style Your Digital Masterpiece</p>
            <ScrollToTopLink to="/CSS_Tutorial_intro"><button type="submit" className="buttonb" id="tutorial-button3">Begin Tutorial</button></ScrollToTopLink>
          </div>

          <div className="beta-sampling" style={tutorialsCodecss}>
            <h1>CSS Example</h1>
          <SyntaxHighlighterComponent code={code2} language="css" />
          <ScrollToTopLink to="/try_css1"><Tryitout /></ScrollToTopLink>
       </div>


          <div className="beta-sample" style={tutorialsCodejs}>
            <h1 style={newCode}><b>JavaScript Tutorial</b></h1>
            <p>Unleash Interactivity and Functionality</p>
            <ScrollToTopLink to="/JavaScript_Tutorial_intro"><button type="submit" className="buttonb" id="tutorial-button3">Begin Tutorial</button></ScrollToTopLink>
          
          </div>


          <div className="beta-sampling" style={tutorialsCodejs}>
            <h1>JavaScript Example</h1>
          <SyntaxHighlighterComponent code={code3} language="js" />
          <ScrollToTopLink to="/try_js13"><Tryitout /></ScrollToTopLink>
       </div>
     
          <div className="beta-sample" style={tutorialsCodephp}>
        
            <h1 style={newCode}><b>PHP Tutorial</b></h1>
            <p>Building Robust Web Solutions</p>
            <ScrollToTopLink to="/PHP_Tutorial_intro"><button type="submit" className="buttonb" id="tutorial-button4">Begin Tutorial</button></ScrollToTopLink>
          </div>


          <div className="beta-sampling" style={tutorialsCodephp}>
            <h1>PHP Example</h1>
          <SyntaxHighlighterComponent code={code4} language="php" />
          <ScrollToTopLink to="/php_functions"><Tryitout /></ScrollToTopLink>
       </div>


          <div className="samplings" style={tutorialsCodepython}>
        
            <h1 style={newCode}><b>Python Tutorial</b></h1>
            <p>Unlock the Potential of Python Prowess</p>
            <ScrollToTopLink to="/Python_Tutorial_intro"><button type="submit" className="buttonb" id="tutorial-button5">Begin Tutorial</button></ScrollToTopLink>
          </div>
          <div className="sampling" style={tutorialsCodesql}>
        
            <h1 style={newCode}><b>SQL Tutorial</b></h1>
            <p>Mastering Data Queries</p>
            <ScrollToTopLink to="/SQL_Tutorial_intro"><button type="submit" className="buttonb" id="tutorial-button6">Begin Tutorial</button></ScrollToTopLink>
          </div>
          <div className="samplings" style={tutorialsCoderuby}>
        
            <h1 style={newCode}><b>Ruby Tutorial</b></h1>
            <p>Gems of Programming</p>
            <ScrollToTopLink to="/Ruby_Tutorial_intro"><button type="submit" className="buttonb" id="tutorial-button7">Begin Tutorial</button></ScrollToTopLink>
          </div>
          <div className="sampling" style={tutorialsCodexml}>
        
            <h1 style={newCode}><b>XML Tutorial</b></h1>
            <p>Structured Data, Infinite Possibilities</p>
            <ScrollToTopLink to="/XML_Tutorial_intro"><button type="submit" className="buttonb" id="tutorial-button8">Begin Tutorial</button></ScrollToTopLink>
          </div>
          <div className="samplings" style={tutorialsCodereact}>
       
            <h1 style={newCode}><b>React Tutorial</b></h1>
            <p>Building Dynamic User Interfaces</p>
            <ScrollToTopLink to="/React_Tutorial_intro"><button type="submit" className="buttonb" id="tutorial-button9">Begin Tutorial</button></ScrollToTopLink>
          </div>
          <div className="sampling" style={tutorialsCodeai}>
         
            <h1 style={newCode}><b>AI Tutorial</b></h1>
            <p>Exploring Artificial Intelligence & Machine Learning</p>
            <ScrollToTopLink to="/AI_Tutorial_intro"><button type="submit" className="buttonb" id="tutorial-button10">Begin Tutorial</button></ScrollToTopLink>
          </div>
        </section>
    
        <iframe width="560" height="315" src="https://www.youtube.com/embed/mZqA4IVjRLc?si=sK4mLd7WEhA7pXOk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>

        
        <div className="xampling"> 
        <img src={require('../image/myimage7.jpg')} alt='code' />
        </div>
        <div className="xam-editor xam" style={{paddingTop: '60px'}}>
        <div className="xxampling code-editor">
  <h1>Online Code Editor</h1>
  <p>You can make use of our frontend code editor to run HTML, CSS and JavaScript code.</p>
  <ScrollToTopLink to="/try_html1"><button class="buttona">Code Editor</button></ScrollToTopLink>
</div>

<div className="xxampling ai-companion">
  <h1>Meet Your Virtual Guide: Beta Thread AI Companion</h1>
  <img src={ai} width={90} height={70} />
  <p>Meet Beta AI</p>
  <ScrollToTopLink to="/ai"><button class="buttona">Get AI Assistance</button></ScrollToTopLink>
</div>

<div className="xxampling kickstart-projects">
  <h1>Kickstart Your Projects</h1>
  <p>Explore our projects and get inspired to start your own!</p>
  <ScrollToTopLink to="/projects"><button class="buttona">View Projects</button></ScrollToTopLink>
</div>

<div className="xxampling code-editor">
  <h1>SVG Editor</h1>
  <p>You can make use of our svg viewer/editor to view and edit your svg files.</p>
  <ScrollToTopLink to="/svgeditor"><button class="buttona">SVG Editor</button></ScrollToTopLink>
</div>

<div className="xxampling ai-companion">
  <h1>Tutorial Tips</h1>
  <p>Your One-Stop Shop for Coding Solutions</p>
  <ScrollToTopLink to="/tips"><button class="buttona">Get Tips</button></ScrollToTopLink>
</div>
</div>



<div style={secondDiv} className="divsample">
 
     <section className="features">
     <div className="progress-sample" style={tutorialsCodehtml}>
    
         <img src={require('../image/beta-progress.svg').default} width={500} height={500}  alt='betathread' />
       <p style={{fontSize: '15px'}}> Our Progress Tracker is designed to help you monitor your progress through our extensive range of tutorials.</p>
       <ScrollToTopLink to="/progress-tracker"><button type="submit" className="buttonb" id="tutorial-button1">Track Progress</button></ScrollToTopLink>
   
     </div>

     <div className="progress-sampling" style={tutorialsCodehtml}>
     <h1 style={{fontSize: '45px'}}>Track Your Progress</h1>
     <DemoProgress />
     </div>
   </section>
  </div>

   
   


<div style={{ position: "relative", paddingBottom: '0px', backgroundColor: 'teal'}}>
  <img src={require('../image/web.png')} style={{ width: "100%", height: "450px"}} alt='code' />
  <img src={require('../image/betathread-teal.svg').default} width={90} height={70} style={{ position: "absolute", top: "0", left: "0" }} alt='betathread' />
  <div className="website">
  <ScrollToTopLink to="/needawebsite" title="Do You need a website?">
      <button class="button-web">Get Started</button>
  </ScrollToTopLink>
  </div>
  </div>

    </body>
  );
}

export default Body;
