import Header from "../components/Header-contact";
import Footer from "../components/Footer";
import Navbar from "../components/navbar";
import Topnav from "../components/topnav";
import '../css/my style.css';
import '../css/my style2.css';
import React, { useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { Helmet } from 'react-helmet';
import styles from "../css/styles.module.css";
import emailjs from '@emailjs/browser';
import { FaEnvelope, FaSms, FaUser } from "react-icons/fa";


export default function Contact() {
  const newCode = {
    background: 'darkblue'
   }
   var h2 = {
    textAlign: 'center'
 }
   const navigate = useNavigate();

   const [successMessage, setSuccessMessage] = useState('');


   const form = useRef();

   const sendEmail = (e) => {
     e.preventDefault();
 
     emailjs.sendForm(
       `${process.env.REACT_APP_SERVICE_ID}`,
       `${process.env.REACT_APP_TEMPLATE_ID}`,
       form.current,
       `${process.env.REACT_APP_PUBLIC_KEY}`
       )
       .then((result) => {
        console.log(result.text);
        setSuccessMessage('Email sent successfully!');
        setTimeout(() => {
            navigate("/")
           }, 2000); // Redirect after 2 seconds
       }, (error) => {
        console.error(error.text);
        setSuccessMessage('Error sending email. Please try again.');
       });
   };
 
    return (
        <body className="main-theme">
        <Helmet>
      
        <title>Contact Us | Beta Thread</title>
    <script src="https://www.google.com/recaptcha/api.js" async defer></script>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-compatible" content="IE-edge"/>
    <meta name="Keywords" content="Contact Beta Thread, Contact us, HTML, Python, CSS, SQL, JavaScript, How to, PHP, XML, MySQL, React, AI, Tutorials, Programming, Web Development, Training, Learning, Quiz, Exercises, Courses, Lessons, Examples, Learn to code, Coding the future, Source code, Website, +2348141772821
    C++ programming tips for real-world applications." />
    <meta name="description" content="This is beta thread contact page where you can contact us using the form we have placed on the pagee.Give us your feedback so we'll know where to improve on our tutorials" />
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />
   
        </Helmet>
         <br />
        <br />
        <br />
        <br />
        <br />
        <Topnav />
        <Header />
        <Navbar />
        <section>
       
        <h1 className={styles.betaheading}>Write your message to here</h1><br />
<form ref={form} onSubmit={sendEmail}>

<div className="input-cnt">
        <input type="text" placeholder="Name in full..." name="user_name" required />
        <i class="material-icons"><FaUser /></i>
    </div>

<div className="input-cnt">
        <input type="email" placeholder="Enter Email" name="user_email" required />
        <i class="material-icons"><FaEnvelope /></i>
    </div>
  
  
    <div className="input-cnt">
        <textarea cols="50" rows="10" placeholder="Your message here..." name="message"></textarea>
        <i class="material-icons"><FaSms /></i>
    </div>
    <div className="input-cnt">
        <input type="submit" value="send" style={newCode} />
    </div>

    {successMessage && <p style={{color: 'white', backgroundColor: 'darkblue', width: '270px', height: '30px'}}>{successMessage}</p>}
      </form>
   
    </section>

    <section>
          
            <p style={h2}>If you have any inquiries or feedback, feel free to reach out to us:</p>
            <address style={h2}>
                Email: betathread10@gmail.com<br />
                Phone: +2348141772821<br />
             
            </address>
        </section>
<br />
        <Footer />
        </body>
    )
}