import React, { useEffect, useState } from 'react';
import { getAuthStatus } from '../../localstorage';
import { useNavigate } from 'react-router-dom';

export default function Flexbox() {
    const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate(); // Move useNavigate here

  useEffect(() => {
    const checkAuthentication = () => {
      const isAuthenticated = getAuthStatus('_id');
      if (!isAuthenticated) {
        navigate("/login");
      } else {
        setAuthenticated(true);
      }
    };

    checkAuthentication();
  }, [navigate]);

    return (
        <div>
            <h1>CSS Flexbox Layout</h1>
            <div style={styles.flexContainer}>
                <div style={styles.flexItem}>Item 1</div>
                <div style={styles.flexItem}>Item 2</div>
                <div style={styles.flexItem}>Item 3</div>
            </div>
            <div>
                <h2>Code Snippet (HTML):</h2>
                <pre>{`
                <div class="flexContainer>
                    <div class="flexItem">Item 1</div>
                    <div class="flexItem">Item 2</div>
                    <div class="flexItem">Item 3</div>
                </div>
                `}</pre>
            </div>
            <div>
                <h2>Code Snippet (CSS):</h2>
                <pre>{`
                /* CSS for Flexbox Layout Component */
               
                    flexContainer {
                        display: flex;
                        justifyContent: space-between;
                        alignItems: center;
                    }
                    flexItem {
                        flex: 1;
                    }
                
                `}</pre>
            </div>
        </div>
    );
}

const styles = {
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flexItem: {
        flex: 1,
    },
};
